import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import JaNavbar from './JaNavbar';
import JaFooter from './JaFooter';

// Language
import { getPackOf } from '../lang';
const LANG = getPackOf('ja');

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>{LANG.頁面標題}</title>
      </Helmet>
      <JaNavbar />
      {children}
      <JaFooter />
    </>
  );
};

export default Layout;
