import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

// Images
import Youtube from '../img/youtube__icon_1.svg';
import Facebook from '../img/fb__icon_1.svg';

// Language
import { getPackOf } from '../lang';
const LANG = getPackOf('ja');

const JaFooter = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/home-ja.md" }) {
        frontmatter {
          location {
            address
            email
            fax
            phone
            title
          }
        }
      }
    }
  `);
  const {
    markdownRemark: {
      frontmatter: { location },
    },
  } = data;

  return (
    <footer id="footer" style={{ backgroundColor: '#222222' }} className="text-left">
      <div className="container">
        <div className="row">
          <div className="col-12 px-0">
            <div className="footer-content text-left">
              <ul>
                <li>
                  <div className="dropdown show">
                    <a
                      className="btn dropdown-toggle pt-0"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {LANG.關於我們}
                    </a>
                    <div className="dropdown-menu p-2">
                      {[
                        { list: LANG.公司簡介, path: '/ja/about/jx' },
                        { list: LANG.經營理念, path: '/ja/about/business' },
                        { list: LANG.產品特色, path: '/ja/about/feature' },
                        { list: LANG.嚴選材料, path: '/ja/about/material' },
                        { list: LANG.生產設備, path: '/ja/about/facility' },
                        { list: LANG.品質與認證, path: '/ja/about/certificate' },
                      ].map((e, i) => {
                        return (
                          <span key={i}>
                            {i !== 0 && <div className="dropdown-divider my-2" />}
                            <Link className="dropdown-item text-secondary p-1" to={e.path}>
                              {e.list}
                            </Link>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </li>
                <li className="text-center px-2">
                  <Link className="px-2" to="/ja/qa">
                    {LANG.產品QA}
                  </Link>
                </li>
                <li className="text-center px-2">
                  <Link className="px-2" to="/ja/#Location">
                    {LANG.聯絡我們}
                  </Link>
                </li>
                <li className="text-center px-2">
                  <a href="https://www.youtube.com/channel/UCoVYoz0K-aiKJIQkNEcqm2w" target="_blank">
                    <img src={Youtube} alt="" height="16" id="Image18" />
                  </a>
                </li>
                <li className="text-center px-2">
                  <a href="https://www.facebook.com/jingxinco/" target="_blank">
                    <img src={Facebook} height="16" id="Image20" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 pt-3 border-top border-secondary">
            <p className="h5">
              <strong>{LANG.景欣股份有限公司}</strong>
            </p>
          </div>
          {location.map((l, i) => {
            return (
              <div className="col-12 col-md-4" key={l.title}>
                <p>
                  <strong>{l.title}:</strong>
                  <br />
                  <small>
                    {LANG.地址}: {l.address}
                    <br />
                    {i === 1 ? (
                      <>
                        {LANG.電話}: {l.phone}
                        <br />
                      </>
                    ) : (
                      <>
                        {LANG.電話}: <a href={`tel:${l.phone.replace(/-/g, '')}`}>{l.phone} </a> <br />
                      </>
                    )}
                    {l.fax ? (
                      <>
                        {LANG.傳真}: {l.fax} <br />
                      </>
                    ) : null}
                    {LANG.聯絡信箱}: {l.email}
                  </small>
                </p>
              </div>
            );
          })}
          <div className="col-12 my-5 pt-3 border-top border-secondary text-right">
            <small>
              <Link to="/ja/privacy">{LANG.使用條款}</Link>&nbsp;&nbsp;|&nbsp;&nbsp; {LANG.使用條款後綴}
              <br />
              Produced by <a href="https://www.b-demo.com">b-demo Design</a>.
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default JaFooter;
