import React, { useEffect, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

// Images
import Logo from '../img/logo_Js.svg';
import ToggleArr from '../img/toggle_link_arr.svg';
import Globe from '../img/lang.svg';

// Language
import { getPackOf } from '../lang';
const LANG = getPackOf('zh');

const Navbar = () => {
  const [query, setQuery] = useState('');
  useEffect(() => {
    // Get initial scroll bar position
    let lastScrollTop = window.pageYOffset;
    window.addEventListener('scroll', () => {
      // Get scroll bar position when scrolling
      const currentPosition = window.pageYOffset;
      // Navbar element
      const getNav = document.getElementById('header');

      // Fix nav bar on top when scrolling up other wise unfix nav bar
      if (currentPosition > lastScrollTop || currentPosition === 0) {
        if (getNav) getNav.classList.remove('fixed-top');
      } else {
        if (getNav) getNav.classList.add('fixed-top');
      }

      // Update last scroll top position
      lastScrollTop = currentPosition <= 0 ? 0 : currentPosition;
    });
  }, []);

  const data = useStaticQuery(graphql`
    {
      roofGroup: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { glob: "**/src/markdown/products/**.md" }
          frontmatter: { category: { eq: "屋頂型" } }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              urlRoute
            }
          }
        }
      }
      scaffoldGroup: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { glob: "**/src/markdown/products/**.md" }
          frontmatter: { category: { eq: "棚架型" } }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              urlRoute
            }
          }
        }
      }
      groundGroup: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { glob: "**/src/markdown/products/**.md" }
          frontmatter: { category: { eq: "地面型" } }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              urlRoute
            }
          }
        }
      }
      elecGroup: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { glob: "**/src/markdown/products/**.md" }
          frontmatter: { category: { eq: "電力相關" } }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              urlRoute
            }
          }
        }
      }
      specGroup: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { glob: "**/src/markdown/products/**.md" }
          frontmatter: { category: { eq: "特殊型" } }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              urlRoute
            }
          }
        }
      }
    }
  `);

  const roofs = data.roofGroup.edges;
  const scaffolds = data.scaffoldGroup.edges;
  const grounds = data.groundGroup.edges;
  const elecs = data.elecGroup.edges;
  const specs = data.specGroup.edges;

  return (
    <nav
      id="header"
      className="navbar navbar-expand-lg navbar-light bg-white border-bottom p-md-0"
      style={{ zIndex: '10' }}
    >
      <div className="container">
        <Link to="/" title="jing-xin Corp." className="navbar-brand">
          <div className="brand-logo py-md-2">
            <img src={Logo} alt="Logo" height="76" className="d-none d-md-block" />
            <img src={Logo} alt="Logo" height="60" className="d-block d-md-none" />
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse text-left" id="navbarSupportedContent" style={{ flexGrow: 0 }}>
          <ul id="bs-menu-ul" className="nav navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown1"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {LANG.太陽能支架}
              </a>
              <div className="dropdown-menu text-nowrap p-2" aria-labelledby="navbarDropdown">
                <h6>
                  <strong>{LANG.屋頂型}</strong>
                </h6>
                {roofs.map((roof) => {
                  return (
                    <Link
                      className="dropdown-item"
                      to={`/product/${roof.node.frontmatter.urlRoute}`}
                      style={{ padding: '0 25px' }}
                      key={roof.node.frontmatter.title}
                    >
                      <img src={ToggleArr} width="5" id="" alt="arrow" /> {roof.node.frontmatter.title}
                    </Link>
                  );
                })}
                <div className="dropdown-divider my-2"></div>
                <h6>
                  <strong>{LANG.棚架型}</strong>
                </h6>
                {scaffolds.map((scaffold) => {
                  return (
                    <Link
                      className="dropdown-item"
                      to={`/product/${scaffold.node.frontmatter.urlRoute}`}
                      style={{ padding: '0 25px' }}
                      key={scaffold.node.frontmatter.title}
                    >
                      <img src={ToggleArr} width="5" id="" alt="arrow" /> {scaffold.node.frontmatter.title}
                    </Link>
                  );
                })}
                <div className="dropdown-divider my-2"></div>
                <h6>
                  <strong>{LANG.地面型}</strong>
                </h6>
                {grounds.map((ground) => {
                  return (
                    <Link
                      className="dropdown-item"
                      to={`/product/${ground.node.frontmatter.urlRoute}`}
                      style={{ padding: '0 25px' }}
                      key={ground.node.frontmatter.title}
                    >
                      <img src={ToggleArr} width="5" id="" alt="arrow" /> {ground.node.frontmatter.title}
                    </Link>
                  );
                })}
                <div className="dropdown-divider my-2"></div>
                <h6>
                  <strong>{LANG.電力相關產品}</strong>
                </h6>
                {elecs.map((elec) => {
                  return (
                    <Link
                      className="dropdown-item"
                      to={`/product/${elec.node.frontmatter.urlRoute}`}
                      style={{ padding: '0 25px' }}
                      key={elec.node.frontmatter.title}
                    >
                      <img src={ToggleArr} width="5" id="" alt="arrow" /> {elec.node.frontmatter.title}
                    </Link>
                  );
                })}
                <div className="dropdown-divider my-2"></div>
                <h6>
                  <strong>{LANG.特殊型支架}</strong>
                </h6>
                {specs.map((spec) => {
                  return (
                    <Link
                      className="dropdown-item"
                      to={`/product/${spec.node.frontmatter.urlRoute}`}
                      style={{ padding: '0 25px' }}
                      key={spec.node.frontmatter.title}
                    >
                      <img src={ToggleArr} width="5" id="" alt="arrow" /> {spec.node.frontmatter.title}
                    </Link>
                  );
                })}
              </div>
            </li>
            <li className="nav-item mr-2">
              <Link className="nav-link" to="/design">
                {LANG.板金工藝設計}
              </Link>
            </li>
            <li className="nav-item mr-2">
              <Link className="nav-link" to="/case">
                {LANG.成功案例}
              </Link>
            </li>
            <li className="nav-item mr-2 p-1">
              <div className="dropdown show d-inline-block">
                <a
                  className="btn btn-sm dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img className="mb-1" src={Globe} alt="" width="18" alt="globe" /> Language
                </a>
                <div className="dropdown-menu p-2">
                  <Link className="dropdown-item text-secondary p-1" to="/en">
                    English
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item text-secondary p-1" to="/">
                    中文
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item text-secondary p-1" to="/ja">
                    日本語
                  </Link>
                </div>
              </div>
            </li>
            <li className="nav-item m-1">
              <div className="input-group input-group-sm">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Serach"
                  aria-label=""
                  aria-describedby="inputGroup-sizing-sm"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <div className="input-group-append">
                  <button className="btn btn-outline-secondary" type="button">
                    <Link to={`/search?query=${query}`} style={{ color: 'inherit' }}>
                      Search
                    </Link>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
