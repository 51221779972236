import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Navbar from './Navbar';
import Footer from './Footer';

// Language
import { getPackOf } from '../lang';
const LANG = getPackOf('zh');

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>{LANG.頁面標題}</title>
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
