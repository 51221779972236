import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';

// Components
import Layout from '../components/Layout';
import EnLayout from '../components/EnLayout';
import JaLayout from '../components/JaLayout';
import UnderConstruction from '../components/UnderConstruction';

// Images
import Gallery from '../img/gallery.gif';

// Language
import { getPackOf } from '../lang';
const LANG_ZH = getPackOf('zh');
const LANG_EN = getPackOf('en');
const LANG_JA = getPackOf('ja');

const getSameCategoryProd = ({ category, title, langData }) => {
  // Return essential data of the same category product except itself
  const sameCategoryProd = [];
  langData.edges
    .filter(
      (edge) =>
        edge.node.frontmatter.category === category &&
        edge.node.frontmatter.title !== title &&
        !edge.node.frontmatter.disable // Ignore disabled product
    )
    .forEach((edge) => {
      sameCategoryProd.push({
        title: edge.node.frontmatter.title,
        subTitle: edge.node.frontmatter.subTitle,
        publicURL: edge.node.frontmatter.productImg ? edge.node.frontmatter.productImg[0].img.publicURL : null,
        urlRoute: edge.node.frontmatter.urlRoute,
      });
    });
  return sameCategoryProd;
};

export const query = graphql`
  query($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        disable
        productImg {
          img {
            publicURL
          }
        }
        bgImg {
          publicURL
        }
        mobBgImg {
          publicURL
        }
        subTitle
        title
        category
        imgDescription
        functions {
          title
          highlight
          content
        }
        installs {
          step
          img {
            publicURL
          }
        }
        videoURL
        patents {
          country
          num
          img {
            publicURL
          }
        }
      }
      html
    }

    zh: allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/src/markdown/products/**.md" } }) {
      edges {
        node {
          frontmatter {
            disable
            title
            subTitle
            productImg {
              img {
                publicURL
              }
            }
            urlRoute
            category
          }
        }
      }
    }

    en: allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/src/markdown/products-en/**.md" } }) {
      edges {
        node {
          frontmatter {
            disable
            title
            subTitle
            productImg {
              img {
                publicURL
              }
            }
            urlRoute
            category
          }
        }
      }
    }

    ja: allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/src/markdown/products-ja/**.md" } }) {
      edges {
        node {
          frontmatter {
            disable
            title
            subTitle
            productImg {
              img {
                publicURL
              }
            }
            urlRoute
            category
          }
        }
      }
    }
  }
`;

const Product = (props) => {
  const [index, setIndex] = useState(0);
  const {
    data: {
      markdownRemark: {
        frontmatter: { disable, title, bgImg, subTitle, mobBgImg, functions, imgDescription, videoURL },
        html,
      },
    },
    pageContext: { langKey },
  } = props;

  const productImg = props.data.markdownRemark.frontmatter.productImg || [];
  const installs = props.data.markdownRemark.frontmatter.installs || [];
  const patents = props.data.markdownRemark.frontmatter.patents || [];

  const sameCategoryProd = getSameCategoryProd({
    category: props.data.markdownRemark.frontmatter.category,
    title: props.data.markdownRemark.frontmatter.title,
    langData: props.data[langKey],
  });

  return (
    <>
      {langKey === 'zh' && (
        <Layout>
          <section id="page_bn" className="" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="position-relative">
              <div className="position-absolute fixed-top text-white" style={{ zIndex: '8' }}>
                <div className="container">
                  <div id="breadcrumb" className="">
                    <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                      <div className="">
                        <div className="col-12 pt-2">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                              <li className="breadcrumb-item">
                                <Link to="/" style={{ color: '#fff' }}>
                                  {LANG_ZH.首頁}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <Link to="/product" style={{ color: '#fff' }}>
                                  {LANG_ZH.太陽能支架}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">{title}</li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-absolute fixed-bottom text-white bk-6">
                <div className="container position-absolute mb-5 fixed-bottom">
                  <div className="col-12 h2">{title}</div>
                  <div className="col-12">{subTitle}</div>
                </div>
              </div>
              {bgImg && <img className="d-none d-md-block main_rwd" src={bgImg.publicURL} alt="background" />}
              {mobBgImg && <img className="d-block d-md-none main_rwd" src={mobBgImg.publicURL} alt="background" />}
            </div>
          </section>
          {disable ? (
            <UnderConstruction />
          ) : (
            <>
              {productImg.length !== 0 && (
                <section id="produ" className="py-3 py-md-5" style={{ backgroundColor: '#efefef' }}>
                  <div className="container ">
                    <div className="row">
                      <div id="BOX" className="col-12 col-md-6">
                        <img className="img-thumbnail p-1" src={productImg[index].img.publicURL} alt="product" />
                      </div>
                      <div className="col-12 col-md-6">
                        <div id="SMALL" className="row px-3 px-md-2">
                          {productImg.map((e, i) => {
                            return (
                              <div
                                className="col p-1"
                                key={e.img.publicURL}
                                onMouseEnter={() => setIndex(i)}
                                onKeyDown={() => setIndex(i)}
                                style={{ cursor: 'pointer' }}
                              >
                                {e.img && <img className="rounded border" src={e.img.publicURL} alt="product" />}
                              </div>
                            );
                          })}
                        </div>
                        <div className="h5 text-secondary my-2">
                          <strong>{imgDescription}</strong>
                        </div>
                        <hr />
                        <div className="h5 text-secondary">
                          <strong>{LANG_ZH.產品特色}</strong>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {functions.length !== 0 && (
                <section id="func" className="my-5">
                  <div className="container">
                    <div className="row">
                      {functions.map((func, i) => {
                        return (
                          <div className="col-6 col-md-4 col-lg my-2 text-center" key={i}>
                            <div className={`card bg-js ${func.highlight && 'bg-danger'}`}>
                              <p className="card-text h5 p-2 text-white">
                                <strong>{func.title}</strong>
                              </p>
                              <div className="card bg-white">
                                <p className="card-text">{func.content}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </section>
              )}
              {installs.length !== 0 || videoURL ? (
                <section id="install_all" className="py-5 text-center text-dark" style={{ backgroundColor: '#bad9f1' }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 my-3">
                        <div className="h2 border-bottom">{LANG_ZH.EZ安裝}</div>
                      </div>
                      {installs.length !== 0 && (
                        <div className="col-12 row mx-0 my-3">
                          {installs.map((ins, i) => {
                            return (
                              <div className="col-6 col-md-3" key={i}>
                                <p className="h3 text-js">{i + 1}</p>
                                <p className="">{ins.step}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {videoURL ? (
                        <div id="ins_video" className="col-12 my-3 d-flex justify-content-center">
                          <div className="col-12 col-md-8">
                            <div className="rwd-16by9">
                              <iframe
                                src={videoURL}
                                frameborder="1"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </section>
              ) : null}
              {patents.length !== 0 && (
                <section id="cert" className="my-5 text-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="my-3 text-secondary">
                          <p className="h2 border-bottom">{LANG_ZH.專利證書}</p>
                          <p>{LANG_ZH.專利證書內文}</p>
                        </div>
                      </div>
                      {patents.map((pat) => {
                        return (
                          <div className="col-4 col-md-2 my-2 p-4" key={pat.num}>
                            {pat.img && <img src={pat.img.publicURL} className="border" alt="patent" />}
                            {pat.country}
                            <br />
                            {pat.num}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </section>
              )}
              <section id="form" className="py-5" style={{ backgroundColor: 'rgba(165,165,165,1.00)' }}>
                <div className="container">
                  <div className="d-flex justify-content-center">
                    <div className="">
                      <div className="contact-link w-50 h6 mb-3">
                        <Link to="/#Location">{LANG_ZH.產品技術諮詢}</Link>
                      </div>
                      <p className="text-dark mx-4">{LANG_ZH.產品技術諮詢內文}</p>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
          <section className="my-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="my-3 text-secondary">
                    <p className="h2 text-center">{LANG_ZH.同類型支架方案}</p>
                  </div>
                  <hr />
                </div>
                {sameCategoryProd.length !== 0
                  ? sameCategoryProd.map(({ title, subTitle, publicURL, urlRoute }) => {
                      return (
                        <div className="col-6 col-md-4 col-lg-3 mb-3 rf" key={urlRoute}>
                          <Link to={`/product/${urlRoute}`}>
                            <div className="card text-secondary">
                              <div
                                className="hover-zoom rounded-top border-bottom"
                                style={{ backgroundImage: `url(${publicURL})` }}
                              >
                                <img src={Gallery} className="card-img-top" />
                              </div>
                              <div className="card-body">
                                <h5 className="card-title text_limit1" style={{ color: 'rgba(108,117,125)' }}>
                                  {title}
                                </h5>
                                <p className="card-text text_limit2">{subTitle}</p>
                                <span className="btn-sm btn-primary">{LANG_ZH.了解產品}</span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  : LANG_ZH.未找到相符的內容}
              </div>
            </div>
          </section>
        </Layout>
      )}
      {langKey === 'en' && (
        <EnLayout>
          <section id="page_bn" className="" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="position-relative">
              <div className="position-absolute fixed-top text-white" style={{ zIndex: '8' }}>
                <div className="container">
                  <div id="breadcrumb" className="">
                    <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                      <div className="">
                        <div className="col-12 pt-2">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                              <li className="breadcrumb-item">
                                <Link to="/en" style={{ color: '#fff' }}>
                                  {LANG_EN.首頁}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <Link to="/en/product" style={{ color: '#fff' }}>
                                  {LANG_EN.太陽能支架}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">{title}</li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-absolute fixed-bottom text-white bk-6">
                <div className="container position-absolute mb-5 fixed-bottom">
                  <div className="col-12 h2">{title}</div>
                  <div className="col-12">{subTitle}</div>
                </div>
              </div>
              {bgImg && <img className="d-none d-md-block main_rwd" src={bgImg.publicURL} alt="background" />}
              {mobBgImg && <img className="d-block d-md-none main_rwd" src={mobBgImg.publicURL} alt="background" />}
            </div>
          </section>
          {disable ? (
            <UnderConstruction />
          ) : (
            <>
              {productImg.length !== 0 && (
                <section id="produ" className="py-3 py-md-5" style={{ backgroundColor: '#efefef' }}>
                  <div className="container ">
                    <div className="row">
                      <div id="BOX" className="col-12 col-md-6">
                        <img className="img-thumbnail p-1" src={productImg[index].img.publicURL} alt="product" />
                      </div>
                      <div className="col-12 col-md-6">
                        <div id="SMALL" className="row px-3 px-md-2">
                          {productImg.map((e, i) => {
                            return (
                              <div
                                className="col p-1"
                                key={e.img.publicURL}
                                onMouseEnter={() => setIndex(i)}
                                onKeyDown={() => setIndex(i)}
                                style={{ cursor: 'pointer' }}
                              >
                                {e.img && <img className="rounded border" src={e.img.publicURL} alt="product" />}
                              </div>
                            );
                          })}
                        </div>
                        <div className="h5 text-secondary my-2">
                          <strong>{imgDescription}</strong>
                        </div>
                        <hr />
                        <div className="h5 text-secondary">
                          <strong>{LANG_EN.產品特色}</strong>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {functions.length !== 0 && (
                <section id="func" className="my-5">
                  <div className="container">
                    <div className="row">
                      {functions.map((func, i) => {
                        return (
                          <div className="col-6 col-md-4 col-lg my-2 text-center" key={i}>
                            <div className={`card bg-js ${func.highlight && 'bg-danger'}`}>
                              <p className="card-text h5 p-2 text-white">
                                <strong>{func.title}</strong>
                              </p>
                              <div className="card bg-white">
                                <p className="card-text">{func.content}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </section>
              )}
              {installs.length !== 0 || videoURL ? (
                <section id="install_all" className="py-5 text-center text-dark" style={{ backgroundColor: '#bad9f1' }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 my-3">
                        <div className="h2 border-bottom">{LANG_EN.EZ安裝}</div>
                      </div>
                      {installs.length !== 0 && (
                        <div className="col-12 row mx-0 my-3">
                          {installs.map((ins, i) => {
                            return (
                              <div className="col-6 col-md-3" key={i}>
                                <p className="h3 text-js">{i + 1}</p>
                                <p className="">{ins.step}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {videoURL ? (
                        <div id="ins_video" className="col-12 my-3 d-flex justify-content-center">
                          <div className="col-12 col-md-8">
                            <div className="rwd-16by9">
                              <iframe
                                src={videoURL}
                                frameborder="1"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </section>
              ) : null}
              {patents.length !== 0 && (
                <section id="cert" className="my-5 text-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="my-3 text-secondary">
                          <p className="h2 border-bottom">{LANG_EN.專利證書}</p>
                          <p>{LANG_EN.專利證書內文}</p>
                        </div>
                      </div>
                      {patents.map((pat) => {
                        return (
                          <div className="col-4 col-md-2 my-2 p-4" key={pat.num}>
                            {pat.img && <img src={pat.img.publicURL} className="border" alt="patent" />}
                            {pat.country}
                            <br />
                            {pat.num}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </section>
              )}
              <section id="form" className="py-5" style={{ backgroundColor: 'rgba(165,165,165,1.00)' }}>
                <div className="container">
                  <div className="d-flex justify-content-center">
                    <div className="">
                      <div className="contact-link w-50 h6 mb-3">
                        <Link to="/en#Location">{LANG_EN.產品技術諮詢}</Link>
                      </div>
                      <p className="text-dark mx-4">{LANG_EN.產品技術諮詢內文}</p>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
          <section className="my-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="my-3 text-secondary">
                    <p className="h2 text-center">{LANG_EN.同類型支架方案}</p>
                  </div>
                  <hr />
                </div>
                {sameCategoryProd.length !== 0
                  ? sameCategoryProd.map(({ title, subTitle, publicURL, urlRoute }) => {
                      return (
                        <div className="col-6 col-md-4 col-lg-3 mb-3 rf" key={urlRoute}>
                          <Link to={`/en/product/${urlRoute}`}>
                            <div className="card text-secondary">
                              <div
                                className="hover-zoom rounded-top border-bottom"
                                style={{ backgroundImage: `url(${publicURL})` }}
                              >
                                <img src={Gallery} className="card-img-top" />
                              </div>
                              <div className="card-body">
                                <h5 className="card-title text_limit1" style={{ color: 'rgba(108,117,125)' }}>
                                  {title}
                                </h5>
                                <p className="card-text text_limit2">{subTitle}</p>
                                <span className="btn-sm btn-primary">{LANG_EN.了解產品}</span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  : LANG_EN.未找到相符的內容}
              </div>
            </div>
          </section>
        </EnLayout>
      )}
      {langKey === 'ja' && (
        <JaLayout>
          <section id="page_bn" className="" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="position-relative">
              <div className="position-absolute fixed-top text-white" style={{ zIndex: '8' }}>
                <div className="container">
                  <div id="breadcrumb" className="">
                    <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                      <div className="">
                        <div className="col-12 pt-2">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                              <li className="breadcrumb-item">
                                <Link to="/ja" style={{ color: '#fff' }}>
                                  {LANG_JA.首頁}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <Link to="/ja/product" style={{ color: '#fff' }}>
                                  {LANG_JA.太陽能支架}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">{title}</li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-absolute fixed-bottom text-white bk-6">
                <div className="container position-absolute mb-5 fixed-bottom">
                  <div className="col-12 h2">{title}</div>
                  <div className="col-12">{subTitle}</div>
                </div>
              </div>
              {bgImg && <img className="d-none d-md-block main_rwd" src={bgImg.publicURL} alt="background" />}
              {mobBgImg && <img className="d-block d-md-none main_rwd" src={mobBgImg.publicURL} alt="background" />}
            </div>
          </section>
          {disable ? (
            <UnderConstruction />
          ) : (
            <>
              {productImg.length !== 0 && (
                <section id="produ" className="py-3 py-md-5" style={{ backgroundColor: '#efefef' }}>
                  <div className="container ">
                    <div className="row">
                      <div id="BOX" className="col-12 col-md-6">
                        <img className="img-thumbnail p-1" src={productImg[index].img.publicURL} alt="product" />
                      </div>
                      <div className="col-12 col-md-6">
                        <div id="SMALL" className="row px-3 px-md-2">
                          {productImg.map((e, i) => {
                            return (
                              <div
                                className="col p-1"
                                key={e.img.publicURL}
                                onMouseEnter={() => setIndex(i)}
                                onKeyDown={() => setIndex(i)}
                                style={{ cursor: 'pointer' }}
                              >
                                {e.img && <img className="rounded border" src={e.img.publicURL} alt="product" />}
                              </div>
                            );
                          })}
                        </div>
                        <div className="h5 text-secondary my-2">
                          <strong>{imgDescription}</strong>
                        </div>
                        <hr />
                        <div className="h5 text-secondary">
                          <strong>{LANG_JA.產品特色}</strong>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {functions.length !== 0 && (
                <section id="func" className="my-5">
                  <div className="container">
                    <div className="row">
                      {functions.map((func, i) => {
                        return (
                          <div className="col-6 col-md-4 col-lg my-2 text-center" key={i}>
                            <div className={`card bg-js ${func.highlight && 'bg-danger'}`}>
                              <p className="card-text h5 p-2 text-white">
                                <strong>{func.title}</strong>
                              </p>
                              <div className="card bg-white">
                                <p className="card-text">{func.content}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </section>
              )}
              {installs.length !== 0 || videoURL ? (
                <section id="install_all" className="py-5 text-center text-dark" style={{ backgroundColor: '#bad9f1' }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 my-3">
                        <div className="h2 border-bottom">{LANG_JA.EZ安裝}</div>
                      </div>
                      {installs.length !== 0 && (
                        <div className="col-12 row mx-0 my-3">
                          {installs.map((ins, i) => {
                            return (
                              <div className="col-6 col-md-3" key={i}>
                                <p className="h3 text-js">{i + 1}</p>
                                <p className="">{ins.step}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {videoURL ? (
                        <div id="ins_video" className="col-12 my-3 d-flex justify-content-center">
                          <div className="col-12 col-md-8">
                            <div className="rwd-16by9">
                              <iframe
                                src={videoURL}
                                frameborder="1"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </section>
              ) : null}
              {patents.length !== 0 && (
                <section id="cert" className="my-5 text-center">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="my-3 text-secondary">
                          <p className="h2 border-bottom">{LANG_JA.專利證書}</p>
                          <p>{LANG_JA.專利證書內文}</p>
                        </div>
                      </div>
                      {patents.map((pat) => {
                        return (
                          <div className="col-4 col-md-2 my-2 p-4" key={pat.num}>
                            {pat.img && <img src={pat.img.publicURL} className="border" alt="patent" />}
                            {pat.country}
                            <br />
                            {pat.num}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </section>
              )}
              <section id="form" className="py-5" style={{ backgroundColor: 'rgba(165,165,165,1.00)' }}>
                <div className="container">
                  <div className="d-flex justify-content-center">
                    <div className="">
                      <div className="contact-link w-50 h6 mb-3">
                        <Link to="/ja#Location">{LANG_JA.產品技術諮詢}</Link>
                      </div>
                      <p className="text-dark mx-4">{LANG_JA.產品技術諮詢內文}</p>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
          <section className="my-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="my-3 text-secondary">
                    <p className="h2 text-center">{LANG_JA.同類型支架方案}</p>
                  </div>
                  <hr />
                </div>
                {sameCategoryProd.length !== 0
                  ? sameCategoryProd.map(({ title, subTitle, publicURL, urlRoute }) => {
                      return (
                        <div className="col-6 col-md-4 col-lg-3 mb-3 rf" key={urlRoute}>
                          <Link to={`/ja/product/${urlRoute}`}>
                            <div className="card text-secondary">
                              <div
                                className="hover-zoom rounded-top border-bottom"
                                style={{ backgroundImage: `url(${publicURL})` }}
                              >
                                <img src={Gallery} className="card-img-top" />
                              </div>
                              <div className="card-body">
                                <h5 className="card-title text_limit1" style={{ color: 'rgba(108,117,125)' }}>
                                  {title}
                                </h5>
                                <p className="card-text text_limit2">{subTitle}</p>
                                <span className="btn-sm btn-primary">{LANG_JA.了解產品}</span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  : LANG_JA.未找到相符的內容}
              </div>
            </div>
          </section>
        </JaLayout>
      )}
    </>
  );
};

export default Product;
